<template>
  <b-modal
    id="folder-modal"
    :title="isEdit ? $t('general.edit') : $t('general.add')"
    :ok-title="isEdit ? $t('general.save') : $t('general.add')"
    :cancel-title="$t('general.cancel')"
    @ok.prevent="saveClick"
  >
     <validation-observer ref="nameValidations">
      <b-form-group :label="$t('general.title') + ':'">
        <validation-provider
          #default="{ errors }"
          rules="required"
          :name="$t('general.title')"
        >
          <b-form-input v-model="item.title" />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </validation-observer>
  </b-modal>
</template>

<script>
import { BModal, BFormGroup, BFormInput } from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";

export default {
  components: {
    BModal,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    data: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
    isEdit: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      required,
      item: {
        title: "",
      },
    };
  },
  watch: {
    data() {
      this.item = this.data;
    },
  },
  methods: {
    async saveClick() {
      const success = await this.$refs.nameValidations.validate();
      if (!success) return;
      this.$emit("save", this.item);
    },
  },
};
</script>

<style></style>

<template>
  <div class="position-relative">
    <b-table
      :fields="fields"
      :items="getItems"
      striped
    >
      <template #head()="{ label }">
        {{ $t(label) }}
      </template>
      <template #cell(title)="{ item }">
        <div
          class="d-flex align-items-center"
          style="cursor: default"
          @dblclick="openItem(item)"
        >
          <feather-icon
            size="20"
            :icon="item.is_folder ? 'FolderIcon' : 'FileIcon'"
          />
          <h5 class="mb-0 ml-50">
            {{ item.title }}
          </h5>
        </div>
      </template>
      <template #cell(modified_date)="{ item }">
        {{ $_dateTimeFormatter(item.modified_date) }}
      </template>
      <template #cell(created_date)="{ item }">
        {{ $_dateTimeFormatter(item.created_date) }}
      </template>
      <template #cell(actions)="{ item }">
        <template v-if="item.id">
          <b-button
            v-if="!item.is_folder"
            class="p-50 mr-50"
            variant="primary"
            @click="copyTest(item)"
          >
            <feather-icon icon="CopyIcon" />
          </b-button>

          <b-button
            class="p-50 mr-50"
            variant="info"
            @click="openItem(item)"
          >
            <feather-icon icon="EyeIcon" />
          </b-button>


          <b-button
            v-if="!item.is_folder"
            class="p-50 mr-50"
            variant="primary"
            @click="openTestInfo(item)"
          >
            <feather-icon icon="InfoIcon" />
          </b-button>
          
          <b-button
            v-if="!item.is_folder"
            class="p-50 mr-50"
            variant="success"
            @click="moveItem(item)"
          >
            <feather-icon icon="MoveIcon" />
          </b-button>
          <!--
            :title="$t('general.edit')" -->
          <b-button
            class="p-50 mr-50"
            variant="warning"
            @click="editClicked(item)"
          >
            <feather-icon icon="EditIcon" />
          </b-button>
          <!--
            :title="$t('general.delete')" -->
          <b-button
            class="p-50"
            variant="danger"
            @click="deleteClicked(item)"
          >
            <feather-icon icon="TrashIcon" />
          </b-button>
        </template>
      </template>
    </b-table>
    <move-test-modal :file-id-to-move="fileIdToMove" />
    <properties-test-modal :properties="properties" />
    <b-overlay
      :show="tableBusy"
      no-wrap
      opacity="0.5"
    />
  </div>
</template>

<script>
import { BTable, BOverlay, BButton } from 'bootstrap-vue'
import formatters from '@/mixins/extra'
import moveTestModal from '../modals/moveTestModal.vue'
import propertiesTestModal from '../modals/propertiesTestModal.vue'

export default {
  components: {
    BTable,
    BOverlay,
    BButton,
    moveTestModal,
    propertiesTestModal,
  },
  mixins: [formatters],
  props: {
    items: {
      type: Array,
      required: true,
    },
    tableBusy: {
      type: Boolean,
      required: false,
      default: false,
    },
    inFolder: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      fileIdToMove: null,
      properties: {},
      fields: [
        {
          key: 'id',
          label: 'tests.id',
        },
        {
          key: 'title',
          label: 'general.title',
        },
        {
          key: 'modified_date',
          label: 'general.updated_at',
        },
        {
          key: 'created_date',
          label: 'general.created_at',
        },
        {
          key: 'actions',
          label: 'general.actions',
          tdClass: 'width-300 text-center',
        },
      ],
    }
  },
  computed: {
    getItems() {
      // this.items.unshift({ id: this.parent, title: '...' })
      if (this.inFolder) {
        const newItems = [{ id: null, title: '...', is_folder: true }].concat(this.items)
        return newItems
      }
      return this.items
    },
  },
  methods: {
    copyTest(item) {
      this.$emit('copyTest', item)
    },
    openItem(item) {
      if (item.is_folder) {
        if (item.id) {
          this.$emit('openFolder', item.id, item)
        } else {
          this.$emit('backToParent')
        }
      } else {
        this.$emit('openItem', item.id)
      }
    },
    editClicked(item) {
      if (item.is_folder) this.$emit('editFolder', item)
      else this.$emit('editTest', item.id)
    },
    deleteClicked(item) {
      this.$_showAreYouSureModal().then(answ => {
        if (answ) {
          this.$emit('deleteItem', item)
        }
      })
    },
    moveItem(item) {
      this.fileIdToMove = item.id
      this.$nextTick(() => {
        this.$bvModal.show('move-test-modal')
      })
    },
    openTestInfo(item) {
      this.properties = item
      this.$nextTick(() => {
        this.$bvModal.show('properties-test-modal')
      })
    },
  },
}
</script>

<style>

</style>

<template>
  <div>
    <section class="d-flex justify-content-between align-items-end">
      <!-- <b-form-group :label="$t('general.search')+':'">
        <b-form-input v-model="query_params.search" />
      </b-form-group> -->
      <!-- <b-form-radio-group
        v-model="itemView"
        class="ml-1 mb-1 list item-view-radio-group"
        buttons
        size="sm"
        button-variant="outline-primary"
      >
        <b-form-radio
          v-for="option in itemViewOptions"
          :key="option.value"
          :value="option.value"
        >
          <feather-icon
            :icon="option.icon"
            size="18"
          />
        </b-form-radio>
      </b-form-radio-group> -->
    </section>
    <section>
      <b-card>
        <div class="d-flex mb-2 align-items-center">
          <b-button
            v-b-modal.folder-modal
            class="p-50"
            variant="primary"
            @click="openNewFolderModal"
          >
            <feather-icon
              size="20"
              icon="FolderPlusIcon"
            />
            {{ $t("tests.add_folder") }}
          </b-button>
          <b-button
            v-b-modal.test-type-select-modal
            class="p-50 ml-2"
            variant="success"
            @click="openTypeSelectModal"
          >
            <feather-icon
              size="20"
              icon="FilePlusIcon"
            />
            {{ $t("tests.add_test") }}
          </b-button>
          <b-breadcrumb>
            <b-breadcrumb-item
              v-for="item in GET_BREADCRUMB"
              :key="item.id"
              :active="item.active"
              @click="item.active ? '' : onClickBreadCrumb(item)"
            >
              {{ item.title }}
            </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
        <table-view
          :items="GET_QUESTION_BASE"
          :table-busy="table_busy"
          :in-folder="Boolean(query_params.parent)"
          @copyTest="copyTest"
          @openFolder="openFolder"
          @openItem="openTestView"
          @editFolder="editFolder"
          @deleteItem="deleteItem"
          @editTest="editTest"
          @backToParent="openFolder(parent_folder_id)"
        />
      </b-card>
    </section>
    <add-or-edit-folder-modal
      :data="selected_folder"
      :is-edit="selected_folder.id"
      @save="saveClick"
    />
    <are-you-sure-modal @copy="copyTestRequest" />
    <test-type-select-modal @save="typeSelected" />
    <b-sidebar
      :key="increment"
      v-model="preview_sidebar"
      width="100vw"
    >
      <test-preview :questions-body="test_preview" />
    </b-sidebar>
  </div>
</template>

<script>
import {
  // BFormRadioGroup, BFormRadio,
  // BFormInput, BFormGroup,
  BButton,
  BCard,
  BSidebar,
  BBreadcrumb,
  BBreadcrumbItem,
} from 'bootstrap-vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import addOrEditFolderModal from './components/modals/addOrEditFolderModal.vue'
import tableView from './components/tables/tableView.vue'
import testTypeSelectModal from './components/modals/testTypeSelectModal.vue'
import testPreview from './components/testPreview.vue'
import areYouSureModal from './components/modals/areYouSureModal.vue'

export default {
  components: {
    // BFormRadioGroup,
    // BFormRadio,
    // BFormInput,
    // BFormGroup,
    BButton,
    BCard,
    addOrEditFolderModal,
    testTypeSelectModal,
    tableView,
    testPreview,
    BSidebar,
    BBreadcrumb,
    BBreadcrumbItem,
    areYouSureModal,
  },
  data() {
    return {
      query_params: {
        search: '',
        parent: null,
      },
      parent_folder_id: null,
      table_busy: false,
      selected_test: null,
      selected_folder: {
        name: '',
        parent: null,
      },
      increment: 999,
      items: [
        {
          id: 1,
          questions: 2,
          subject: 'Maths',
          title: 'test',
          is_folder: true,
          created_at: '2022-01-31',
          updated_at: '2022-02-28',
        },
        {
          id: 2,
          questions: 3,
          subject: 'Maths',
          title: 'test 2',
          is_folder: false,
          created_at: '2022-01-30',
          updated_at: '2022-02-25',
        },
      ],
      itemView: '',
      itemViewOptions: [
        { icon: 'GridIcon', value: 'grid-view' },
        { icon: 'ListIcon', value: 'list-view' },
      ],
      test_preview: {},
      preview_sidebar: false,
    }
  },
  computed: {
    ...mapGetters('questions', [
      'GET_QUESTION_BASE',
      'GET_TEST_BASE_PARAMS',
      'GET_BREADCRUMB',
    ]),
  },
  mounted() {
    this.query_params = this.GET_TEST_BASE_PARAMS
    this.openFolder(this.query_params.parent)
  },
  methods: {
    ...mapActions('questions', [
      'FETCH_QUESTION_BASE',
      'ADD_QUESTION_FOLDER',
      'EDIT_QUESTION_FOLDER',
      'FETCH_ONE_QUESTION_FOLDER',
      'DELETE_QUESTION',
      'DELETE_QUESTION_FOLDER',
      'FETCH_ONE_QUESTION',
      'COPY_TEST',
    ]),
    ...mapMutations('questions', ['SET_TEST_BASE_PARAMS', 'SET_BREADCRUMB']),
    openNewFolderModal() {
      this.selected_folder = {
        name: '',
        parent: this.query_params.parent,
      }
    },
    refresh() {
      this.FETCH_QUESTION_BASE(this.query_params).then(() => {
        this.table_busy = false
      })
    },
    saveClick(item) {
      (item.id
        ? this.EDIT_QUESTION_FOLDER(item)
        : this.ADD_QUESTION_FOLDER(item)
      ).then(() => {
        this.$bvModal.hide('folder-modal')
        this.refresh()
      })
    },
    copyTestRequest() {
      console.log('selected ', this.selected_test)
      this.COPY_TEST({
        question_id: this.selected_test,
      }).then(() => {
        this.$bvModal.hide('are-you-sure-modal')
        this.refresh()
      })
    },
    openFolder(id, item) {
      // this.parent_folder_id = this.$_removeObjectConnection(this.query_params.parent)
      this.query_params.parent = id
      this.table_busy = true
      if (item) {
        this.SET_BREADCRUMB({ id, title: item.title, type: 'enter' })
      }
      if (id) {
        this.SET_BREADCRUMB({ type: 'toBack', id })
      } else {
        this.SET_BREADCRUMB({ type: 'toBack', id: 0 })
      }
      this.SET_TEST_BASE_PARAMS(this.query_params)
      this.refresh()
      if (this.query_params.parent) {
        this.FETCH_ONE_QUESTION_FOLDER(this.query_params.parent).then(
          resp => {
            this.parent_folder_id = resp.data.parent
          },
        )
      } else {
        this.parent_folder_id = null
      }
    },
    editFolder(item) {
      this.selected_folder = this.$_removeObjectConnection(item)
      this.$bvModal.show('folder-modal')
    },
    copyTest(item) {
      console.log(item)
      this.selected_test = item.id
      this.$bvModal.show('are-you-sure-modal')
    },
    deleteItem(item) {
      (item.is_folder
        ? this.DELETE_QUESTION_FOLDER(item.id)
        : this.DELETE_QUESTION(item.id)
      ).then(() => {
        this.refresh()
      })
      // this.EDIT_QUESTION_FOLDER({ is_active: false, id }).then(() => {
      // })
    },
    openTypeSelectModal() {},
    typeSelected(type) {
      this.$router.push({
        name: 'testing-add-question',
        params: { folder_id: this.query_params.parent || 0, type },
      })
    },
    editTest(id) {
      this.$router.push({ name: 'testing-edit-question', params: { id } })
    },
    openTestView(id) {
      this.FETCH_ONE_QUESTION(id).then(resp => {
        this.test_preview = resp
        this.increment += 1
        this.preview_sidebar = true
      })
    },
    onClickBreadCrumb(item) {
      this.SET_BREADCRUMB({ type: 'toBack', id: item.id })
      if (item.id === 0) {
        this.openFolder(null)
      } else {
        this.openFolder(item.id)
      }
    },
  },
}
</script>

<style></style>

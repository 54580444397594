<template>
  <b-modal
    id="move-test-modal"
    :cancel-title="$t('general.cancel')"
    :ok-title="$t('general.save')"
    size="sm"
    centered
    no-close-on-backdrop
    hide-footer
    @ok.prevent="saveClick"
    @hide="onhide"
    @show="onShow"
  >
    <div>
      <b-row>
        <b-col cols="12">
          <b-table
            :fields="fields"
            :items="getItems"
            striped
          >
            <template #head()="{ label }">
              {{ $t(label) }}
            </template>
            <template #cell(title)="{ item }">
              <div
                class="d-flex align-items-center"
                style="cursor: default"
                @dblclick="openItem(item)"
              >
                <feather-icon
                  size="20"
                  :icon="item.is_folder ? 'FolderIcon' : 'FileIcon'"
                />
                <h5 class="mb-0 ml-50">
                  {{ item.title }}
                </h5>
              </div>
            </template>
            <template #cell(actions)="{ item }">
              <b-button
                v-if="!item.is_folder"
                class="p-50"
                variant="info"
                @click="selectQuestion(item)"
              >
                <feather-icon icon="ChevronRightIcon" />
              </b-button>
            </template>
          </b-table>
          <b-button
            variant="success"
            block
            @click="onSelectCurrentFolder"
          >
            {{ $t('general.select') }}
          </b-button>
        </b-col>
      </b-row>
    </div>
    <b-sidebar
      v-model="preview_sidebar"
      width="100vw"
    >
      <test-preview :questions-body="question" />
    </b-sidebar>
  </b-modal>
</template>

<script>
import {
  BModal, BRow, BCol, BTable, BButton, BSidebar,
} from 'bootstrap-vue'
// import draggable from 'vuedraggable'
import { mapActions, mapGetters } from 'vuex'
import testPreview from '@/views/modules/Test/components/testPreview.vue'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    // draggable,
    BTable,
    BButton,
    testPreview,
    BSidebar,
    // BCard,
  },
  props: {
    defSelectedQuestions: {
      type: Array,
      required: false,
      default: () => [],
    },
    fileIdToMove: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      selected_questions: [],
      preview_sidebar: false,
      items: [],
      parent_folder_id: null,
      question: {},
      in_folder: false,
      query_params: {
        search: '',
        parent: null,
      },
      fields: [
        {
          key: 'title',
          label: 'general.title',
        },
        // {
        //   key: 'actions',
        //   label: 'general.actions',
        // },
      ],
    }
  },
  computed: {
    ...mapGetters('questions', ['GET_QUESTION_BASE', 'GET_QUESTION_BASE_TO_MOVE']),
    getItems() {
      // this.items.unshift({ id: this.parent, title: '...' })
      if (this.in_folder) {
        const newItems = [{ id: this.parent_folder_id, title: '...', is_folder: true }].concat(this.GET_QUESTION_BASE_TO_MOVE)
        return newItems
      }
      return this.GET_QUESTION_BASE_TO_MOVE
    },
    selectedQuestionIds() {
      return this.selected_questions.map(el => el.id)
    },
  },
  mounted() {
    this.FETCH_QUESTION_BASE_TO_MOVE(this.query_params)
  },
  methods: {
    ...mapActions('questions', ['FETCH_QUESTION_BASE', 'FETCH_ONE_QUESTION_FOLDER', 'FETCH_ONE_QUESTION', 'EDIT_QUESTION', 'FETCH_QUESTION_BASE_TO_MOVE']),
    saveClick() {
      this.$emit('saveClick', this.selected_questions)
    },
    selectQuestion(item) {
      if (!this.selectedQuestionIds.includes(item.id)) {
        this.selected_questions.push(item)
      }
    },
    refresh() {
      this.FETCH_QUESTION_BASE_TO_MOVE(this.query_params)
    },
    openFolder(id) {
      this.query_params.parent = id
      // this.table_busy = true
      this.refresh()
      if (this.query_params.parent) {
        this.FETCH_ONE_QUESTION_FOLDER(this.query_params.parent).then(resp => {
          this.parent_folder_id = resp.data.parent
          this.in_folder = true
        })
      } else {
        this.parent_folder_id = null
        this.in_folder = false
      }
    },
    previewTest(id) {
      this.FETCH_ONE_QUESTION(id).then(resp => {
        this.question = resp
        this.preview_sidebar = true
      })
    },
    removeQuestion(index) {
      this.selected_questions.splice(index, 1)
    },
    openItem(item) {
      if (item.is_folder) {
        this.openFolder(item.id)
      } else {
        this.previewTest(item.id)
      }
      // item.is_folder ? this.openFolder(item.id) : this.previewTest(item.id)
    },
    onSelectCurrentFolder() {
      this.EDIT_QUESTION({ folder: this.query_params.parent, id: this.fileIdToMove }).then(() => {
        this.$_successToast(this.$t('general.edited'))
        this.FETCH_QUESTION_BASE()
        this.$bvModal.hide('move-test-modal')
      })
    },
    onhide() {
      this.selected_questions = []
    },
    onShow() {
      this.selected_questions = this.defSelectedQuestions
    },
  },
}
</script>

<style>

</style>

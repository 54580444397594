var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"position-relative"},[_c('b-table',{attrs:{"fields":_vm.fields,"items":_vm.getItems,"striped":""},scopedSlots:_vm._u([{key:"head()",fn:function(ref){
var label = ref.label;
return [_vm._v(" "+_vm._s(_vm.$t(label))+" ")]}},{key:"cell(title)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-items-center",staticStyle:{"cursor":"default"},on:{"dblclick":function($event){return _vm.openItem(item)}}},[_c('feather-icon',{attrs:{"size":"20","icon":item.is_folder ? 'FolderIcon' : 'FileIcon'}}),_c('h5',{staticClass:"mb-0 ml-50"},[_vm._v(" "+_vm._s(item.title)+" ")])],1)]}},{key:"cell(modified_date)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$_dateTimeFormatter(item.modified_date))+" ")]}},{key:"cell(created_date)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$_dateTimeFormatter(item.created_date))+" ")]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [(item.id)?[(!item.is_folder)?_c('b-button',{staticClass:"p-50 mr-50",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.copyTest(item)}}},[_c('feather-icon',{attrs:{"icon":"CopyIcon"}})],1):_vm._e(),_c('b-button',{staticClass:"p-50 mr-50",attrs:{"variant":"info"},on:{"click":function($event){return _vm.openItem(item)}}},[_c('feather-icon',{attrs:{"icon":"EyeIcon"}})],1),(!item.is_folder)?_c('b-button',{staticClass:"p-50 mr-50",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.openTestInfo(item)}}},[_c('feather-icon',{attrs:{"icon":"InfoIcon"}})],1):_vm._e(),(!item.is_folder)?_c('b-button',{staticClass:"p-50 mr-50",attrs:{"variant":"success"},on:{"click":function($event){return _vm.moveItem(item)}}},[_c('feather-icon',{attrs:{"icon":"MoveIcon"}})],1):_vm._e(),_c('b-button',{staticClass:"p-50 mr-50",attrs:{"variant":"warning"},on:{"click":function($event){return _vm.editClicked(item)}}},[_c('feather-icon',{attrs:{"icon":"EditIcon"}})],1),_c('b-button',{staticClass:"p-50",attrs:{"variant":"danger"},on:{"click":function($event){return _vm.deleteClicked(item)}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}})],1)]:_vm._e()]}}])}),_c('move-test-modal',{attrs:{"file-id-to-move":_vm.fileIdToMove}}),_c('properties-test-modal',{attrs:{"properties":_vm.properties}}),_c('b-overlay',{attrs:{"show":_vm.tableBusy,"no-wrap":"","opacity":"0.5"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
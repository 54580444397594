<template>
  <b-modal
    id="are-you-sure-modal"
    :title="$t('general.pleace_confirm')"
    :ok-title="$t('general.ok')"
    :cancel-title="$t('general.no')"
    no-close-on-backdrop
    centered
    @ok="saveClick"
  >
    <p>
      {{ $t("courses.are_you_sure_want_copy") }}
    </p>
  </b-modal>
</template>

<script>
import { BModal, BFormGroup } from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import vSelect from "vue-select";

export default {
  components: {
    BModal,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  data() {
    return {
      required,
      type: null,
    };
  },
  methods: {
    saveClick() {
      this.$emit("copy", this.type);
    },
  },
};
</script>
